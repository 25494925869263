import "./for-legal-entities.scss";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import FileContainer from "@components/file/file.container";

const ForLegalEntities = ({ data }) => {
	const { context: { edges: context } = {} } = data;
	const { files: { edges: files } = {} } = data;
	let i = 0;
	return (
		<section className="full-width for-legal-entities">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="for-legal-entities__header">Юридическим лицам</h2>
					</div>
				</div>
			</div>

			<div className="grid-container">
				<div className="row">
					{context.map(({ node }) => {
						i += 1;
						const {
							childMarkdownRemark: {
								frontmatter: { file: path, name } = {}
							} = {}
						} = node;
						return (
							<div
								key={i}
								className={`col-xs-12 ${
									i % 2
										? "col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5"
										: "col-md-4 col-sm-5"
								}`}
							>
								<FileContainer
									className="for-legal-entities__file"
									name={name}
									path={path}
									files={files}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query Get {
				context: allFile(
					filter: { relativeDirectory: { eq: "for-legal-entities" } }
				) {
					edges {
						node {
							childMarkdownRemark {
								frontmatter {
									file
									name
								}
							}
						}
					}
				}
				files: allFile(
					filter: {
						sourceInstanceName: { eq: "assets" }
						extension: { regex: "/pdf|docx|doc|xlsx|xls/" }
					}
				) {
					edges {
						node {
							name
							relativePath
							prettySize
							root
							sourceInstanceName
							base
							birthTime
							ext
							extension
						}
					}
				}
			}
		`}
		render={data => <ForLegalEntities data={data} {...props} />}
	/>
);
